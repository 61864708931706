import { useEffect, useState } from "react";
import { DeleteEmployee, ShowAllEmployee } from "../../../apis/Employee";
import { Link, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
export default function AllMonthInvoice(params) {
    const [Employee ,SetEmployee]=useState();  
     const [show, setShow] = useState(false);
     const [selectedemployee, setselectedemployee] = useState();
     const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setselectedemployee(item);
        setShow(true);
    }
    useEffect(() => {
        const fetchData = async () => {
          try {
            const userFromApi = await ShowAllEmployee();
            SetEmployee(userFromApi);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
    return <>
       
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Employee data</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div id="order-listing_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                            <div id="order-listing_filter" className="sortable-table-1">
                                                <label>
                                                    <input type="search" className="form-control" placeholder="Search" aria-controls="order-listing" />
                                                    </label>
                                                    </div>
                                                    </div>
                                        <div className="col-sm-12 col-md-6 alignend">
                                        <Link type="button" to={"/Employee/create"} className="btn btn-light btn-icon-text ">
                                                            new Employee
                                                            <i className="typcn typcn-user-add btn-icon-append"></i>                          
                                                            </Link>
                                        </div>    
                                    </div>
                                    
                                    <div className="row">
                                        <div className="">
                                            <table id="order-listing" className="table table-bordered" role="grid" aria-describedby="order-listing_info">
                                                <thead>
                                                  <tr>
                                                      <th  className="sortStyle ">#</th>
                                                      <th  className="sortStyle " colSpan={2}>name</th>
                                                      <th  className="sortStyle ">phone</th>
                                                      <th className="sortStyle ">email</th>
                                                      <th className="sortStyle ">national id</th>
                                                      <th className="sortStyle ">hrcode</th>
                                                      <th className="sortStyle ">location</th>
                                                      <th className="sortStyle ">JobTitel</th>
                                                      <th className="sortStyle ">employee date</th>
                                                      <th className="sortStyle ">pdf</th>
                                                      <th className="sortStyle ">qrcode</th>
                                                      <th className="sortStyle center" colSpan={2}>action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {

                                                        Employee?Employee.status==200? Employee.data.map( (item,index) =>{
                                                        return<tr role="row" className="odd" key={index}>
                                                        <td className="sorting_1">{index+1}</td>
                                                          <td><img src={"http://scanapi.agricapital-eg.com/"+item.profile_image}/> </td>
                                                          <td>{item.name}</td>
                                                          <td>{item.phone}</td>
                                                          <td>{item.email}</td>
                                                          <td>{item.national_id}</td>
                                                          <td>{item.hrcode}</td>
                                                          <td>{item.location}</td>
                                                          <td>{item.JobTitel}</td>
                                                          <td>{item.employee_date}</td>
                                                          <td><a href={"http://scanapi.agricapital-eg.com/"+item.pdf}  target="blank">downlod pdf</a></td>
                                                          <td><a href={"http://scanapi.agricapital-eg.com/"+item.qrcode} target="blank" >downlod qrcode</a></td>
                                                        

                                                        <td>
                                                        <Link type="button" to={"/Employee/"+item.id+"/edit"} className="btn btn-outline-secondary btn-icon-text">
                                                            Edit
                                                            <i className="typcn typcn-edit btn-icon-append"></i>                          
                                                            </Link>
                                                        </td>
                                                        <td>
                                                        <button type="button" className="btn btn-outline-danger btn-icon-text" onClick={()=>handleShow(item)}>
                                                            Delete
                                                            <i className="typcn typcn-edit btn-icon-append"></i>                          
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    }):<tr><td style={{textAlign:"center"}} colSpan={12}>{Employee.data}</td></tr>:<tr><td style={{textAlign:"center"}} colSpan={12}>waiting data ...</td></tr>
                                                        
                                                    }
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {selectedemployee?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>are you sure ?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={()=>{
            DeleteEmployee(selectedemployee?.id);
            const updatedEmployees = Employee.data.filter(employee => employee.id !== selectedemployee.id);
            SetEmployee(prevState => ({ ...prevState, data: updatedEmployees }));
            handleClose();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
}