import { useState,useEffect } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import {  showEmployee ,UpdateEmployees} from "../../../apis/Employee";
import { useNavigate, useParams } from 'react-router';

export default function UpdateMonthInvoice(params) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [formErrorData, setFormErrorData] = useState({});
  const [Employee ,SetEmployee]=useState();  
    const navigate = useNavigate();
    const { id } = useParams();

 


  const handleFileChange = (event) => {
      const { name, value } = event.target;
      const file = event.target.files[0];
      setFormData({
        ...formData,
        [name]: file,
      });
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const userShowApi = await showEmployee(id);
            // SetEmployee(userFromApi.data);
            setFormData(userShowApi.data);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
    const handleSubmit = (event) => {
      event.preventDefault();
      if(!formData?.phone||!formData?.name||!formData?.employee_date||!formData?.email||!formData?.profile_image||!formData?.pdf||!formData?.national_id||!formData?.hrcode||!formData?.location){
        const errors = {};

        if (!formData?.name) {
          errors.name = "The name is required";
        }
    
        if (!formData?.phone) {
          errors.phone = "The phone is required";
        }
        
        if (!formData?.email) {
          errors.email = "The email is required";
        }
        
        if (!formData?.profile_image) {
          errors.profile_image = "The profile image is required";
        }
        
        if (!formData?.pdf) {
          errors.pdf = "The pdf is required";
        }
        
        if (!formData?.national_id) {
          errors.national_id = "The national id is required";
        }
        
        if (!formData?.hrcode) {
          errors.hrcode = "The hrcode is required";
        }
        if (!formData?.location) {
          errors.location = "The location is required";
        }
        if (!formData?.employee_date) {
          errors.employee_date = "The employee date is required";
        }
        setFormErrorData(errors);            
    }
    else{
        setFormErrorData([]);
        
        console.log(formData);
        UpdateEmployees(formData,id).then((res)=>{
          console.log(formData);
          console.log(res);
          if(res.status<202){
            alert(res.data);
            navigate('/Employee');
          }
          else{
            console.log(res);
          }
        }).catch(function (error) {
          // handle error
          console.log(error);
        });
       
    }
    };
    return<>
                <div className="card">
                <div className="card-body">
                    <h4 className="card-title mb-5">Employee data</h4>
    <Form onSubmit={handleSubmit} >
    <div className='row'>
    <div className='col-lg-6 col-sm-12 mt-5'>
          <Form.Label>name</Form.Label>
           <Form.Control
            type="text"
            name="name"
            value={formData?.name}
            onChange={handleInputChange}
          />
          {formErrorData.name?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.name}</label>:<></>}
           
          </div>
          <div className='col-lg-6 col-sm-12 mt-5'>
          <Form.Label>phone number</Form.Label>
           <Form.Control
            type="text"
            name="phone"
            value={formData?.phone}
            onChange={handleInputChange}
          />
          {formErrorData.phone?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.phone}</label>:<></>}
           
          </div>
          <div className='col-lg-4 col-sm-12 mt-5'>
          <Form.Label>national id</Form.Label>
           <Form.Control
            type="number"
            name="national_id"
            value={formData?.national_id}
            onChange={handleInputChange}
          />
          {formErrorData.national_id?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.national_id}</label>:<></>}
           
          </div>
          <div className='col-lg-4 col-sm-12 mt-5'>
          <Form.Label>hrcode</Form.Label>
           <Form.Control
            type="text"
            name="hrcode"
            value={formData?.hrcode}
            onChange={handleInputChange}
          />
          {formErrorData.hrcode?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.hrcode}</label>:<></>}
           
          </div>
          <div className='col-lg-4 col-sm-12 mt-5'>
          <Form.Label>location</Form.Label>
           <Form.Control
            type="text"
            name="location"
            value={formData?.location}
            onChange={handleInputChange}
          />
          {formErrorData.location?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.location}</label>:<></>}
           
          </div>
          <div className='col-lg-6 col-sm-12 mt-5'>
          <Form.Label>email</Form.Label>
           <Form.Control
            type="email"
            name="email"
            value={formData?.email}
            onChange={handleInputChange}
          />
          {formErrorData.email?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.email}</label>:<></>}
           
          </div>
          <div className='col-lg-6 col-sm-12 mt-5'>
          <Form.Label>employee date</Form.Label>
           <Form.Control
            type="date"
            name="employee_date"
            value={formData?.employee_date}
            onChange={handleInputChange}
          />
          {formErrorData.employee_date?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.employee_date}</label>:<></>}
           
          </div>


          <div className='col-12 mt-5'>
          <Form.Label>profile image</Form.Label>
           <Form.Control
            type="file"
            name="profile_image"
            onChange={handleFileChange}
          />
          {formErrorData.profile_image?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.profile_image}</label>:<></>}
           
          </div>
          <div className='col-12 mt-5'>
          <Form.Label>pdf</Form.Label>
           <Form.Control
            type="file"
            name="pdf"
            onChange={handleFileChange}
          />
          {formErrorData.pdf?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.pdf}</label>:<></>}
           
          </div>
    </div>
      <div className="d-flex justify-content-between mt-5"> 
          <Button variant="primary" type="submit">
            Submit
          </Button>
      </div>
    </Form> </div>
            </div>
    </>
}