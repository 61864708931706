export default function SideBarStiky(params) {
    return<div class="theme-setting-wrapper">
    <div id="theme-settings" class="settings-panel">
      <i class="settings-close typcn typcn-delete-outline"></i>
      <p class="settings-heading">SIDEBAR SKINS</p>
      <div class="sidebar-bg-options" id="sidebar-light-theme">
        <div class="img-ss rounded-circle bg-light border me-3"></div>
        Light
      </div>
      <div class="sidebar-bg-options selected" id="sidebar-dark-theme">
        <div class="img-ss rounded-circle bg-dark border me-3"></div>
        Dark
      </div>
      <p class="settings-heading mt-2">HEADER SKINS</p>
      <div class="color-tiles mx-0 px-4">
        <div class="tiles success"></div>
        <div class="tiles warning"></div>
        <div class="tiles danger"></div>
        <div class="tiles primary"></div>
        <div class="tiles info"></div>
        <div class="tiles dark"></div>
        <div class="tiles default border"></div>
      </div>
    </div>
  </div>
}