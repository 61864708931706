import React, { useEffect, useState } from 'react';
import { GetEmp } from '../../../apis/Employee';
import { useParams } from 'react-router';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
function EmployeeData() {
    const [employee, setFormData] = useState({});
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
          try {
            const userShowApi = await GetEmp(id);
            // SetEmployee(userFromApi.data);
            setFormData(userShowApi.data);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
    return (
        <>    <Navbar expand="lg" className="bg-body-tertiary scannav">
        <Container>
          <Navbar.Brand href="#home">AGRO GROUP</Navbar.Brand>
        </Container>
      </Navbar>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="card scandata">

                        <div className="card-body informationdata">
                            <div className="text-center">
                                <img src={"http://scanapi.agricapital-eg.com/"+employee.profile_image} alt={employee.name} className="img-fluid" />
                            </div>
                            <div className="mt-3 row wid-80">
                                <h3 className='card-title'>Agro Group</h3>
                                <h5 className="card-text col-4 tit">Name :</h5>
                                <h5 className="card-text col-8 ">{employee.name}</h5>
                                <h5 className="card-text col-4 tit">Hrcode :</h5>
                                <h5 className="card-text col-8 ">{employee.hrcode}</h5>
                                <h5 className="card-text col-4 tit">National Id :</h5>
                                <h5 className="card-text col-8 ">{employee.national_id}</h5>
                                <h5 className="card-text col-4 tit">Location :</h5>
                                <h5 className="card-text col-8 ">{employee.location}</h5>
                                <h5 className="card-text col-4 tit">Job Title :</h5>
                                <h5 className="card-text col-8 ">{employee.JobTitel}</h5>
                                <h5 className="card-text col-4 tit">Join Date :</h5>
                                <h5 className="card-text col-8 ">{employee.employee_date}</h5>
                                <h5 className="card-text col-4 tit">Phone :</h5>
                                <a className="card-text col-8 tel" href={'tel:'+employee.phone} >{employee.phone}</a>
                                <h5 className="card-text col-4 tit">Email :</h5>
                                <a className="card-text col-8 tel" href={'mailto:'+employee.email} >{employee.email}</a>
                               </div>
                        </div>
                    </div>
                </div>
            </div>

        <Swiper
        slidesPerView={3}
        spaceBetween={10}
        
        rewind={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>                                <img src={require("../../../4.png")} alt={employee.name} className="img-fluid" />
</SwiperSlide>
        <SwiperSlide>                                <img src={require("../../../12.png")} alt={employee.name} className="img-fluid" />
</SwiperSlide>
        <SwiperSlide>                                <img src={require("../../../logoo.jpg")} alt={employee.name} className="img" />
</SwiperSlide>

      </Swiper>
      <footer className="Footer" >
        <div className="container " data-aos="fade-right">
        <div className='row'>
        <div className="col-lg-4 col-sm-12 mt-30" >
         <p>AgriCapital Ventures Limited is committed to connecting global resources to opportunities outside its locality and confinement. We are a trusted partner to farmers while also helping agriprenuers in the entire agricultural value chain with needed solutions and advisory support.</p>
        
        </div>

    <div className="col-lg-4 col-sm-12">
    <div className=" ">
        <div className="row ">
        <h1>Company Web Site</h1>
    
        <a className="Home" href='https://agricapital-eg.com/' target='blank'>agricapital</a>
        </div>

        <div className="socialfooter mt-30">
         <h1>Contact Us</h1>
         <ul>
          <li><i class="fa-solid fa-location-dot "></i><span className='ml-2'>Build 17,New Cairo , Fifth Sttlement North Shouayfat </span></li>
          <li><i class="fa-solid fa-phone"></i><span className='ml-2'><a href='tel:01070680050' >01070680050</a></span></li>
          <li><i class="fa-brands fa-google"></i><span className='ml-2'><a href='mailto:info@agricapital-eg.com'>info@agricapital-eg.com</a></span></li>
          <li><i class="fa-brands fa-google"></i><span className='ml-2'><a href='mailto:Sales@agricapital-eg.com'>Sales@agricapital-eg.com</a></span></li>
         </ul>
         </div>
    </div>
    </div>
    <div className="col-lg-4 col-sm-12">
    <div className="container ">
        <div className="socialfooter mt-30">
         <h1>social With us</h1>
        <a href="https://www.facebook.com/agricapitalng" target="blank"><i className="fa-brands fa-facebook-f facebook"></i></a>
        <a href="https://www.instagram.com/agricapitalng/"target="blank"> <i className="fa-brands fa-instagram instagram"></i></a>   
         </div>
    </div>
    </div>
        </div>
    </div>
   
      
    <div className="sign">
    agricapital Egypt © All Rights Reserved 2023


        </div> 
            </footer>
      </div>
        </>
    );
}

export default EmployeeData;
