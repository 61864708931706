import { Icon } from '@iconify/react';
import { useState,useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import { logout , UserData } from '../Api';
export default function Navbar(params) {
    const navigate = useNavigate();
    const [User ,SetUser]=useState(); 
    useEffect(() => {
        const fetchData = async () => {
          try {
            const userFromApi = await UserData();
            SetUser(userFromApi.user);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
    const handleLogout = async () => {
        try {
          // Make an API request to log out the user
          await logout();
          // Remove the authentication token from local storage or cookie
          localStorage.removeItem('token');
          // Redirect to the login page after logout
          navigate('/login');
        } catch (error) {
          console.error('Logout failed:', error);
        }
      };
    return<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <a class="navbar-brand brand-logo" href="index.html">agrogroup</a>
      <a class="navbar-brand brand-logo-mini" href="index.html">agrogroup</a>
      <button class="navbar-toggler navbar-toggler align-self-center d-none d-lg-flex" type="button" data-toggle="minimize">
        
        <Icon icon="typcn:th-menu" />

      </button>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <ul class="navbar-nav mr-lg-2">

        <li class="nav-item  d-none d-lg-flex">
          <a class="nav-link active" href="#">
            Statistic
          </a>
        </li>
       
      </ul>
      <ul class="navbar-nav navbar-nav-right">
       
        <li class="nav-item dropdown d-flex">
          <a class="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center " id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            <Icon class="typcn typcn-message-typing" icon="typcn:message-typing" />
            <span class="count bg-success">2</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list " aria-labelledby="messageDropdown">
            <p class="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <img src="../../images/faces/face4.jpg" alt="image" class="profile-pic"/>
              </div>
              <div class="preview-item-content flex-grow">
                <h6 class="preview-subject ellipsis font-weight-normal">David Grey
                </h6>
                <p class="font-weight-light small-text mb-0">
                  The meeting is cancelled
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <img src="../../images/faces/face2.jpg" alt="image" class="profile-pic"/>
              </div>
              <div class="preview-item-content flex-grow">
                <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook
                </h6>
                <p class="font-weight-light small-text mb-0">
                  New product launch
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <img src="../../images/faces/face3.jpg" alt="image" class="profile-pic"/>
              </div>
              <div class="preview-item-content flex-grow">
                <h6 class="preview-subject ellipsis font-weight-normal"> Johnson
                </h6>
                <p class="font-weight-light small-text mb-0">
                  Upcoming board meeting
                </p>
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item dropdown  d-flex">
          <a class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            <Icon class="typcn typcn-bell me-0"icon="typcn:bell" />

            <span class="count bg-danger">2</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
            <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-success">
                  <i class="typcn typcn-info-large mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">Application Error</h6>
                <p class="font-weight-light small-text mb-0">
                  Just now
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-warning">
                  <i class="typcn typcn-cog mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">Settings</h6>
                <p class="font-weight-light small-text mb-0">
                  Private message
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-info">
                  <i class="typcn typcn-user-outline mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">New user registration</h6>
                <p class="font-weight-light small-text mb-0">
                  2 days ago
                </p>
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle pl-0 pr-0" href="#" data-bs-toggle="dropdown" id="profileDropdown" aria-expanded="false">
            <Icon class="typcn " icon="typcn:user-outline" />

          <span class="nav-profile-name">{User?.name}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <a class="dropdown-item">
            <i class="typcn typcn-cog text-primary"></i>
            Settings
            </a>
            <a class="dropdown-item" onClick={handleLogout}>
            <i class="typcn typcn-power text-primary"></i>
            Logout
            </a>
          </div>
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        <span class="typcn typcn-th-menu"></span>
      </button>
  </div>
</nav>
}
