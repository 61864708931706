import { useState,useEffect } from "react";
import {  UserData } from "../Api";
import { Link } from "react-router-dom";

export default function SidBar(params) {
    const [User ,SetUser]=useState(); 
    useEffect(() => {
        const fetchData = async () => {
          try {
            const userFromApi = await UserData();
            SetUser(userFromApi.user);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
    return<>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      {
        User? <ul class="nav">
        <li class="nav-item">
          <div class="d-flex sidebar-profile">
            <div class="sidebar-profile-image">
              <img src="http://scanapi.agricapital-eg.com\images\1705279661.png" alt="image"/>
              <span class="sidebar-status-indicator"></span>
            </div>
            <div class="sidebar-profile-name">
              <p class="sidebar-name">
                {User.name}
              </p>
              <p class="sidebar-designation">
                {User.department} {User.job_role}
              </p>
            </div>
          </div>
          <div class="nav-search">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Type to search..." aria-label="search" aria-describedby="search"/>
              <div class="input-group-append">
                <span class="input-group-text" id="search">
                  <i class="typcn typcn-zoom"></i>
                </span>
              </div>
            </div>
          </div>
          <p class="sidebar-menu-title">public menu</p>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/">
            <i class="typcn typcn-device-desktop menu-icon"></i>
            <span class="menu-title">Dashboard </span>
          </Link>
        </li>
        
        <li class="nav-item">
          <Link class="nav-link" to="/Employee">
            <i class="typcn typcn-chart-pie menu-icon"></i>
            <span class="menu-title">Employee</span>
          </Link>
        </li>
      </ul>:<></>
  
      }
      
      </nav>
    </>
}