// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route   } from 'react-router-dom';
import Login from './layout/Login';
import Dashboard from './layout/Dashboard';
import "./sass/main.css";
import Content from './veiws/Hr/Content';
import MonthInvoice from './veiws/Hr/Monthinvoice/Index';
import AllMonthInvoice from './veiws/Hr/Monthinvoice/AllMonthInvoice';
import CreatMonthInvoice from './veiws/Hr/Monthinvoice/Create';
import UpdateMonthInvoice from './veiws/Hr/Monthinvoice/Update';
import EmployeeData from './veiws/Hr/Monthinvoice/EmployeeData';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/" element={<Dashboard/>} >
          <Route index element={<Content/>}/>
          
        <Route path="/Employee" element={<MonthInvoice/>} >
          <Route index element={<AllMonthInvoice/>}/>
          <Route path="create" element={<CreatMonthInvoice/>} />
          <Route path=":id/edit" element={<UpdateMonthInvoice/>} />
        </Route>

        </Route>
        <Route path="employeescan/:id" element={<EmployeeData/>} />

      </Routes>
    </Router>
  );
};

export default App;